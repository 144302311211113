var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("el-input", {
        attrs: { readonly: "", placeholder: _vm.placeholder },
        nativeOn: {
          click: function ($event) {
            _vm.selectDialog = true
          },
        },
        model: {
          value: _vm.names,
          callback: function ($$v) {
            _vm.names = $$v
          },
          expression: "names",
        },
      }),
      _c(
        "el-dialog",
        {
          staticClass: "dialog-mini custom-dialog user-dialog",
          attrs: {
            "append-to-body": "",
            "destroy-on-close": true,
            width: "850px",
            title: "选择角色",
            visible: _vm.selectDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.selectDialog = $event
            },
          },
        },
        [
          _vm.selectDialog
            ? _c("selectUsersCom", {
                attrs: {
                  "selectUsers:sync": _vm.selectRoleList,
                  show: _vm.selectDialog,
                  loginKey: "loginRole",
                  users: _vm.selectRoles,
                  userNames: _vm.names,
                },
                on: {
                  "update:show": function ($event) {
                    _vm.selectDialog = $event
                  },
                  "update:users": function ($event) {
                    _vm.selectRoles = $event
                  },
                  "update:userNames": function ($event) {
                    _vm.names = $event
                  },
                  "update:user-names": function ($event) {
                    _vm.names = $event
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }